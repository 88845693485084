<template>
    <section>
        <ProductZoomer
        :base-images="images"
        :base-zoomer-options="zoomerOptions"
        />
    </section>
</template>

<script>
export default {
    name:'product-zoomer-ailem',
    data () {
        return {
            'zoomerOptions': {
                zoomFactor: 3, // scale for zoomer
                pane: 'pane', // three type of pane ['pane', 'container-round', 'container']
                hoverDelay: 200, // how long after the zoomer take effect
                namespace: 'zoomer-bottom', // add a namespace for zoomer component, useful when on page have mutiple zoomer
                move_by_click:true, // move image by click thumb image or by mouseover
                scroll_items: 5, // thumbs for scroll
                choosed_thumb_border_color: "#bbdefb", // choosed thumb border color
                scroller_button_style: "line",
                scroller_position: "bottom",
                zoomer_pane_position: "right"
            }
        }
    },
    computed: {
         images() {
            let images = {
                thumbs: [
                    {'id':'1', 'url': 'https://picsum.photos/seed/picsum/200'},
                    {'id':'2', 'url': 'https://picsum.photos/seed/picsum/200'},
                    {'id':'3', 'url': 'https://picsum.photos/seed/picsum/200'}
                ],
                normal_size : [
                    {'id':'1', 'url': 'https://picsum.photos/seed/picsum/200'},
                    {'id':'1', 'url': 'https://picsum.photos/seed/picsum/200'},
                    {'id':'3', 'url': 'https://picsum.photos/seed/picsum/200'}
                ],
                large_size : [
                    {'id':'1', 'url': 'https://picsum.photos/seed/picsum/200'},
                    {'id':'2', 'url': 'https://picsum.photos/seed/picsum/200'},
                    {'id':'3', 'url': 'https://picsum.photos/seed/picsum/200'}
                ]
            }
            //  for ( let x = 0; x < this.photos.length; x++) {
            //     images.large_size.push({id:x, url:this.photos[x]})
            //     images.thumbs.push({id:x, url:this.photos[x]})
            //     images.normal_size.push({id:x, url:this.photos[x]})
            // }
            return images
        }
    },
    props: {

    }
}
</script>
<style scoped>
.responsive-image.preview-box {
    width: 100%;
}
.limit-x {
    flex: none
}
</style>