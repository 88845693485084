<template>
    <section class="badges">
        <div class="row my-2 py-2 border-top border-bottom">
            <div class="content-wrapper">
                <div class="row">
                    <div class="col-md-6 limit-x">
                        <product-zoomer-ailem />
                    </div>
                    <div class="col-md-6">
                        <h3>Coffret de soin</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus, officia provident deserunt quidem debitis distinctio culpa placeat et ducimus, est magnam consequatur nesciunt deleniti molestiae doloribus ut reprehenderit. Porro, distinctio!</p>
                        <h3> 2€ - <span class="text-muted">7€</span></h3>
                        <div class="my-5">
                            <button class="btn btn-gradient-primary">Ajouter au panier</button>
                            <b-badge variant="outline-primary mx-2">0</b-badge>
                            <!-- <button class="btn btn-gradient-secondary">0</button> -->
                        </div>
                        <string-text
                            v-for="(description, descriptionId) in text"
                            :key="descriptionId"
                            :items="description"
                            :edit="false"
                            :editable="editable"
                            :description-index="text.indexOf(description)"
                            @remove-text="text.splice(text.indexOf($event), 1)"
                            @push-text="
                            text.splice($event['index'], 0, $event['text'])
                            "
                            @typographie-text="textTypographie(description, $event)"
                        >
                        </string-text>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import productZoomerAilem from './product-zoomer-ailem.vue';
import stringText from './string-text.vue';
export default {
    name: 'product-details',
    components:{
        productZoomerAilem,
        stringText
    },
    data () {
        return {
            
        }
    },
    props:{
       editable:Boolean,
       description:String,
       title:String,
       price:String,
       sectionTitle:String,
       text:Array
    },
    computed: {
    },
    methods:{
    textTypographie(elem, data) {
      if (data["type"] == "size") {
        elem.data["paragraph-font-size"] = data["value"];
      }
      if (data["type"] == "justify") {
        elem.data["paragraph-font-justify"] = data["value"];
      }
      if (data["type"] == "weight") {
        elem.data["paragraph-font-weight"] = data["value"];
      }
      if (data["type"] == "style") {
        elem.data["paragraph-font-style"] = data["value"];
      }
    },
    },
    mounted () {
    }
}
</script>
<style scoped>


</style>